import request from '@/tools/request';
import { Res } from '@/types/common';

/**
 * 获取应用安装状态
 * @param loading
 * @returns
 */
export const getInstallStatus = loading => {
  return request({
    url: '/loqateauto-index-guide.html',
    loading,
  }) as Promise<Res<{ is_install_script: 0 | 1 }>>;
};

export const uninstall = loading => {
  return request({
    url: '/loqateauto-index-del.html',
    loading,
  });
};
export const install = loading => {
  return request({
    url: '/loqateauto-index-set.html',
    loading,
  });
};
