<template>
  <div class="home">
    <header>
      <m-page-header title="使用指南"></m-page-header>
    </header>
    <main>
      <FormSkeleton :loading="loading" type="text">
        <div class="description">
          <ul>
            <li>1.地址补充提示，默认开启；如果关闭，商城前台将不显示图标和备注</li>
            <li>2.展示国家，默认选择所有国家；如果需要限制某些国家，请手动取消对应勾选的国家</li>
            <li>3.地址填充需在地址栏输入三位字符后开始调用；未输入三个字符以上不会调用</li>
            <li>
              4.余额扣除，搜索关键字符后点击联想的地址信息并完成了信息补充才会被记录为一次正确调用；每次正确调用会扣除对应的额度。
            </li>
          </ul>
        </div>
        <div class="images">
          <m-image-preview :imgList="previews" />
        </div>
        <div class="btn">
          <el-button
            :type="isInstall ? 'danger' : 'primary'"
            size="medium"
            @click="setPlugin"
            :loading="btnLoading"
          >
            {{ isInstall ? '卸载插件' : '安装插件' }}
          </el-button>
        </div>
      </FormSkeleton>
    </main>
  </div>
</template>

<script>
import MPageHeader from '@/components/pageHeader/PageHeader.vue';
import MImagePreview from '@/components/imagePreview/ImagePreview.vue';
import { getInstallStatus, uninstall, install } from '../api/home';
import HOME_PREVIEW_1 from '../statics/img/home1.png';
import HOME_PREVIEW_2 from '../statics/img/home2.png';
import HOME_PREVIEW_3 from '../statics/img/home3.png';
import { ref, defineComponent } from 'vue';
import { ElMessage } from 'element-plus';
import FormSkeleton from '@/components/formSkeleton/FormSkeleton.vue';

const previews = [HOME_PREVIEW_1, HOME_PREVIEW_2, HOME_PREVIEW_3];

export default defineComponent({
  name: 'Home',
  components: {
    'm-page-header': MPageHeader,
    'm-image-preview': MImagePreview,
    FormSkeleton,
  },
  setup() {
    const loading = ref(true);
    const isInstall = ref(false); // 是否已安装
    const btnLoading = ref(false);

    // 获取安装状态
    const setInstallStatus = async () => {
      const { data, status } = await getInstallStatus(loading);

      if (!status) return;
      isInstall.value = !!data.is_install_script;
    };

    // 卸载 / 安装 插件
    const setPlugin = async () => {
      if (isInstall.value) {
        const res = await uninstall(btnLoading);
        if (!res.status) return;

        ElMessage.success('卸载成功');
        isInstall.value = !isInstall.value;
      } else {
        const res = await install(btnLoading);
        if (!res.status) return;
        ElMessage.success('安装成功');
        isInstall.value = !isInstall.value;
      }
    };

    return {
      setPlugin,
      setInstallStatus,
      isInstall,
      btnLoading,
      loading,
      previews,
    };
  },

  mounted() {
    this.setInstallStatus();
  },
});
</script>

<style lang="scss" scoped>
main {
  padding: 20px;

  .description {
    ul {
      li {
        padding: 4px 0;
        line-height: 1.5;
        .title {
          padding: 10px 0;
        }
      }
    }
  }
  .images {
    padding: 20px 0;
  }
}
</style>
